import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import {
  Heading,
  Container,
  Box,
  Flex,
} from '@chakra-ui/react'

import { PrismicRichText, SliceZone } from '@prismicio/react'
import { components } from "../../components/Slices"
import PageSubMenu from "../../components/PageSubMenu/PageSubMenu"


const ThankYouPage = ({ data, location }) => {
  const doc = data.prismicPlacemakingPage.data;

  return (
    <Layout>
     {doc.submenu.document !== null &&
      <PageSubMenu 
        menuItems={doc.submenu.document.data.page_submenu}
        location={location}
      />}
     <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column">
            <Box mt="12">
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.title.text}
              </Heading>
              <Box textAlign={{base: "center", md: "left"}}>
                <PrismicRichText field={doc.page_content.richText} />
              </Box>
            </Box>
          </Flex>
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
query PleinAirPageQuery {
  prismicPlacemakingPage(uid: {eq: "mugler-plaza-details-and-community-events"}) {
    uid
    data {
      title {
        text
      }
      body {
        ... on PrismicPlacemakingPageDataBodyNewsletterSignup {
          id
          slice_type
          primary {
            newsletter_signup_title {
              text
            }
            newsletter_signup_subtitle {
              text
            }
            newsletter_signup_button_label
            newsletter_signup_background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicPlacemakingPageDataBodyCallToAction {
          id
          slice_type
          primary {
            link_label
            link {
              url
            }
            call_to_action_title {
              text
            }
            call_to_action_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            call_to_action_content {
              richText
            }
          }
        }
        ... on PrismicPlacemakingPageDataBodyRichText {
          id
          slice_type
          items {
            content {
              richText
            }
          }
        }
      }
      submenu {
        document {
          ... on PrismicPageSubmenu {
            id
            data {
              page_submenu {
                menu_item_link {
                  url
                }
                menu_item_label
              }
            }
          }
        }
      }
      page_content {
        richText
      }
    }
  }
}
`

export default ThankYouPage

export const Head = ({ data }) => <title>{data.prismicPlacemakingPage.data.title.text}</title>
